import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import ScrollMagic from "scrollmagic";

interface State {
  scriptCookieGtm: boolean;
  viewTransitionLoader: boolean;
  lockScroll: boolean;
  minCharsSearch: number;
  timeoutTransition: number;
}

interface Context {
  state: State;
  dispatch: React.Dispatch<Action>;
  registerShouldAnimate: (element: HTMLElement) => () => void;
  componentsToAnimate: Set<HTMLElement>;
  deniedAnimationNodes: Set<HTMLElement>;
  registerDeniedAnimationNode: (element: HTMLElement) => () => void;
  registerControllerScrollAnimations: (animationActive?: boolean) => () => void;
  controllerScrollAnimations: ScrollMagic.Controller | null;
  releaseScroll: () => void;
  lockScroll: () => void;
}

interface Action {
  type: string;
  payload: any;
}

interface SiteProviderProps {
  children: ReactNode;
}

const initState: State = {
  scriptCookieGtm: false,
  viewTransitionLoader: false,
  lockScroll: false,
  minCharsSearch: 3,
  timeoutTransition: 800,
};

// eslint-disable-next-line no-redeclare
const SiteContext = createContext<Partial<Context>>({});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "write_script_gtm":
      return { ...state, scriptCookieGtm: action.payload };
    case "view_transition_loader":
      return { ...state, viewTransitionLoader: action.payload };
    case "lock_scroll": {
      return {
        ...state,
        lockScroll: true,
      };
    }
    case "toggle_scroll": {
      return {
        ...state,
        lockScroll: !state.lockScroll,
      };
    }
    case "release_scroll": {
      return {
        ...state,
        lockScroll: false,
      };
    }
    default:
      return state;
  }
};

/**
 *
 * @param {React.ReactElement} children
 * @returns {JSX.Element}
 * @constructor
 */
const SiteProvider = ({ children }: SiteProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const bodyElement = useRef<HTMLElement | null>(null);

  const lockScroll = useCallback(() => {
    if (bodyElement.current) {
      bodyElement.current.classList.add("body--lock");
    }
  }, []);

  const releaseScroll = useCallback(() => {
    if (bodyElement.current) {
      bodyElement.current.classList.remove("body--lock");
    }
  }, []);

  useLayoutEffect(() => {
    bodyElement.current = document.querySelector("body");
  }, []);

  const timeoutTransition = state?.timeoutTransition;

  const componentsToAnimate = useRef(new Set<HTMLElement>());
  const deniedAnimationNodes = useRef(new Set<HTMLElement>());

  const controllerScrollAnimations = useRef<ScrollMagic.Controller | null>(
    null,
  );

  const registerShouldAnimate = useRef((element: HTMLElement) => {
    componentsToAnimate.current.add(element);

    return () => {
      componentsToAnimate.current.delete(element);
    };
  });

  const registerDeniedAnimationNode = useRef((element: HTMLElement) => {
    deniedAnimationNodes.current.add(element);

    return () => {
      deniedAnimationNodes.current.delete(element);
    };
  });

  const registerControllerScrollAnimations = useRef(
    (animationActive = false) => {
      let timeout: NodeJS.Timeout;
      if (!controllerScrollAnimations.current && animationActive) {
        controllerScrollAnimations.current = new ScrollMagic.Controller();

        timeout = setTimeout(() => {
          // TODO: fix updateScene API issue
          controllerScrollAnimations?.current?.updateScene(true);
        }, timeoutTransition);
      }

      return () => {
        if (controllerScrollAnimations.current) {
          controllerScrollAnimations.current.destroy(true);
          controllerScrollAnimations.current = null;
          clearTimeout(timeout);
        }
      };
    },
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
      registerShouldAnimate: registerShouldAnimate.current,
      componentsToAnimate: componentsToAnimate.current,
      deniedAnimationNodes: deniedAnimationNodes.current,
      registerDeniedAnimationNode: registerDeniedAnimationNode.current,
      registerControllerScrollAnimations:
        registerControllerScrollAnimations.current,
      controllerScrollAnimations: controllerScrollAnimations.current,
      releaseScroll,
      lockScroll,
    }),
    [state, releaseScroll, lockScroll],
  );

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

const useSiteContext = () => {
  const context = useContext(SiteContext);

  if (!context) throw new Error("Error while retrieving Site context");

  return context;
};

const useRegisterShouldAnimate = () => {
  const siteContext = useSiteContext();

  return siteContext.registerShouldAnimate;
};

const useComponentsToAnimate = () => {
  const siteContext = useSiteContext();

  return siteContext.componentsToAnimate;
};

const useRegisterDeniedAnimationNodes = () => {
  const siteContext = useSiteContext();

  return siteContext.registerDeniedAnimationNode;
};

const useDeniedAnimationNodes = () => {
  const siteContext = useSiteContext();

  return siteContext.deniedAnimationNodes;
};

const useRegisterControllerScrollAnimations = () => {
  const siteContext = useSiteContext();

  return siteContext.registerControllerScrollAnimations;
};

const useControllerScrollAnimations = () => {
  const siteContext = useSiteContext();

  return siteContext.controllerScrollAnimations;
};

const useMinCharsSearch = () => {
  const { state } = useSiteContext();

  return state?.minCharsSearch || 1;
};

export {
  SiteProvider,
  useSiteContext,
  useRegisterShouldAnimate,
  useComponentsToAnimate,
  useRegisterDeniedAnimationNodes,
  useDeniedAnimationNodes,
  useRegisterControllerScrollAnimations,
  useControllerScrollAnimations,
  useMinCharsSearch,
};
